.form-control-table {
    border: none; /* Elimina el borde */
    background-color: transparent; /* Fondo transparente */
    box-shadow: none; /* Elimina sombras */
    padding: 0; /* Reduce el espacio interno */
    font-size: inherit; /* Usa el tamaño de fuente de la tabla */
    width: 100%; /* Ajusta al ancho de la celda */
  }
  
  .form-control-table:focus {
    border-bottom: 1px solid #007bff; /* Opción: resalta al enfocar */
    background-color: #f8f9fa; /* Cambia el fondo para que se note */
    outline: none; /* Elimina el contorno por defecto */
  }