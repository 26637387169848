:root {
  --gris: #F2F2F2;
  --azul: #303AF2;
  --naranja: #F29F05;
  --negro: #0D0D0D;

}

.btn-azul {
  background-color: transparent;
  color: var(--azul) !important;
  font-size: 1.5em !important;
  font-weight: 500 !important;
  font-family: 'Roboto', sans-serif !important;
  text-align: start !important;
}

.navbar-transparente{
  background-color: transparent !important;
}

nav.bg-light {
  
  background-color:  transparent!important;
}

.container-login {
  width: 100%;
  background: #fff;
  margin-top: 50px;
  box-shadow: 0 1px 11px rgba(0, 0, 0, 0.27);
  border-radius: 5px;
  padding: 30px;
}


.center-accion{
  display: flex;
  justify-content: center ;
}

.form-check-input[type=radio]{
  border: #0D0D0D solid 1px !important;
}
#nav-alumno{
  color:var(--azul)
}

  .rAXpye {
    color: rgb(217,48,37);
    padding-left: .25em;
}

.accion-table-alumno{
  display: flex;
  justify-content: space-between;
}

.barra{

  display: flex;
  align-items: center;
  justify-content: center;
}

