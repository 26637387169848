.container-cursos-page{
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container-title{
    display: flex;
}

.container-table {
    display: flex;
    flex-direction: column;
}


.container-add-new-curso {
    display: flex;
    align-items: center;
    justify-content: center;
}