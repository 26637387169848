/*SUBTITULO*/
.custom-container-title2 {
  border-bottom: solid #ff9f4b;
  border-width: 2px;
}

/*ESTADISTICAS*/

.custom-list-group-item {
  border: none; /* Sin borde para cada ListGroupItem */
  padding: 10px 15px; /* Espaciado interno para los elementos */
  /*background-color: #f8f9fa; */
  font-size: 0.9rem;
}

/*CARD*/
.custom-card {
    background: linear-gradient(270deg, #ffffff, #f1f1f1);
    border-color: #e6e6e6;
    border-radius: 0.375rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }
  
  .custom-card:hover {
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  
  .custom-card-img {
    object-fit: cover;
    width: 100px;
    height: auto;
    border-radius: 0.375rem 0 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    transition: transform 0.3s ease;
  }
  
  .custom-card-img:hover {
    transform: scale(1.05);
  }
  
  .custom-card-title {
   /* font-size: 16px;*/
    padding-left: 0.3rem;
    padding-top: 1.5rem;
  }

  .custom-card-title span:first-child {
    text-transform: uppercase;
  }
  
  .custom-card-title span:last-child {
    font-size: 14px;
  }


  .custom-buttongroup {
  border-color: transparent;
}

/* Botones personalizados */
.custom-left-button,
.custom-right-button {
  color: rgb(60, 60, 60) !important; /* Texto Gris oscuro */
  text-transform: uppercase;
  font-size: 12px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  border-radius: 0 0 0.375rem 0.375rem; /* Solo los bordes inferiores son redondeados */
  border: none !important; /* Sin bordes */
}

.custom-left-button, .custom-right-button, .custom-left-button.active, .custom-right-button.active,  .custom-left-button:hover, .custom-left-button.active:hover, .custom-right-button:hover, .custom-right-button.active:hover {
  font-weight: bold;
}


.custom-left-button {
  background-color: #ffffff !important; /* Durazno por defecto (Habilitado)*/
}

.custom-right-button {
  background-color: #d05300  !important; /* Rojo cuando está en "Ausente" */
  color:white !important; /* Texto blanco */
}

/* Colores cuando están activos */
.custom-left-button.active {
  background-color: hsl(40, 100%, 87%) !important; /* Tiza más fuerte cuando está presionado (Inhabilitado) */
  color: rgb(60, 60, 60) !important; /* Texto negro */
}

.custom-right-button.active {
  background-color: hsl(180, 38%, 53%) !important; /* Verde menta por defecto (Presente) */
  color: white !important;
}

/* Hover State */
.custom-left-button:hover {
  background-color: #e9e9e9 !important; /* Durazno claro al hacer hover */
  color: rgb(60, 60, 60) !important; /* Texto negro al hacer hover */
}

/* Hover State cuando están activos */
.custom-left-button.active:hover {
  background-color: #ebebeb !important; /* Durazno más oscuro al hacer hover cuando activo */
}


.custom-right-button:hover {
  background-color: #fdb4ba !important; /* Coral más fuerte al hacer hover cuando está en "Ausente" */
  color: white !important;
}

.custom-right-button.active:hover {
  background-color: #c8eed1 !important; /* Durazno claro al hacer hover */
  color: rgb(60, 60, 60) !important; /* Texto negro al hacer hover */
}
